import {useContext} from 'react' ;
import {Link} from'react-router-dom' ;

import {UserContext} from '../../context/UserContext.js' ;
import './menu.css' ;

const headerList = [
	['Home', '/'], ['About Us', '/about'],  ['Products', '/products'],
	['Distributors', '/distributors'], ['Contact', '/contact'] 
] ;

const Menu = ({onLogoutClick, closeCallback}) => {	
	const {user} = useContext(UserContext) ;
	
	const checkAdmin = () => {	
		return (
			<div className="mini-menu">
				{headerList.map((head,i)=><Link className="menu-item" key={i} to={head[1]}>{head[0]}</Link>)}
			</div>
		) ;
	}

	const checkLoggedIn = () => {
		if(user.name)
			return (
				<div className="menu-btns">
					<Link className="menu-btn" to="/profile"> {user.name} </Link>
					<button className="menu-btn"  onClick={onLogoutClick}> Logout </button>
				</div>
				) ;
		else
			return (
				<div className="menu-btns">
					<Link className="menu-btn" to='/login'> Login </Link>
					<Link className="menu-btn" to='/register'> Register </Link>
				</div>
			) ;
	}

	return <div className="menu" onClick={closeCallback}> {checkAdmin()} {checkLoggedIn()} </div>
}

export default Menu ;