import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons' ;

import useInterval from './useInterval.js' ;
import Card2 from '../card/Card2.js' ;
import './slider.css' ;

const Slider = ({interval, autoplay, arr, width}) => {
	const [page, setPage] = useState(0) ;
	
	const onNextClick = () => setPage((page+1)%arr.length)

	const onPrevClick = () => setPage((page+arr.length-1)%arr.length)
	
	useInterval( onNextClick, (interval?interval:5000) ) ;

	const createDots = () => {
		return arr.map( (im,i) => <div key={i} className="slider-dot" onClick={() => setPage(i)}/> ) ;
	}

	const generatePage = () => {
		const {name, price, packing, banner, image, sno, stock} = arr[page] ;
		return ( 
			<div className="slider-page">
				<img src={banner} alt={name} />
				{ (window.screen.availWidth > 600)?<Card2 name={name} packing={packing} price={price} image={image} sno={sno} stock={stock} />:null }
			</div>
		) ;
	}

	return (
		<div className="slider-cont" style={ {width: `${width}%` } }>
			{generatePage()}

			<button className="slider-btn btn-left" onClick={onPrevClick}> <FontAwesomeIcon icon={faChevronLeft} /> </button> 
			<button className="slider-btn btn-right" onClick={onNextClick}> <FontAwesomeIcon icon={faChevronRight} /> </button>

			<div className="slide-nav">	{createDots()} </div>

		</div>
	);
}

export default Slider ;