import {useEffect, useState, useContext} from 'react';
import { faUser, faPhoneFlip } from '@fortawesome/free-solid-svg-icons' ;

import Title from '../title/Title.js' ;
import { addNotif, remNotif } from '../notif.js' ;
import { invalidEmail, invalidMobile, isBlank, invalidName } from '../valid.js' ;
import LoginForm from '../forms/LoginForm.js' ;
import TextBoxIcon from '../textboxicon/TextBoxIcon.js' ;
import TextArea from '../text/TextArea.js' ;
import './contact.css' ;
import {UserContext} from '../../context/UserContext.js' ;

const initData = { name: '', mobile: '', email: '', message: ''} ;

const Contact = ({styles, onb1Click, b1}) => {
	const [data, setData] = useState(initData) ;
	const [error, setError] = useState('') ;
	const {user} = useContext(UserContext) ;

	const {name, email, mobile, message} = data ;
 	
	useEffect( () => {
		if(user.name) {
			const {name, mobile, email} = user ;
			setData({...data, name, email, mobile}) ;
		}
	//eslint-disable-next-line
	}, [user] ) ;

	const onSendClick = () => {
		if(error !== '')
			setError('You cannot proceed without fixing all the errors') ;
		else if(invalidName(name))
			setError(invalidName(name) );
	  	else if(invalidEmail(email))
			setError(invalidEmail(email) );
		else if(invalidMobile(mobile))
			setError(invalidMobile(mobile) );
	  	else if(isBlank(message, 'Message'))
			setError(isBlank(message, 'Message') );
		else
		{	
			addNotif('Please Wait...') ;

			fetch('https://api.myarthhardware.com/message',{
				method : 'post' ,
				headers : { 'Content-Type' : 'application/json'} ,
				body : JSON.stringify({ name, mobile, email, message }) ,
			})
			.then(res => {
				if(res.ok)
					return res.json() ;
				else
					throw Error(res.statusText) ;
			})
			.then(data => {	
				setData(initData) ;
				remNotif() ;
				addNotif('Successfully Sent Message/Feedback', 'success') ;
			}) 
			.catch( err  => {
				console.log(err) ; 
				remNotif() ;
				addNotif('There was a problem with sending message', 'error') ;
			}) ;
		}
	}

	const onInputChange = (event) => {
		setData({...data, [event.target.name] : event.target.value}) ;
		setError('') ;
	}

	return(
		<div className = 'contact'>
			<Title name="Contact Us"  arr={[[ 'Home', '/'], ['Contact Us', '/contact']]} />
			<LoginForm title=" Send Your Message / Feedback " error={error} b1={b1} onb1Click={onb1Click} b2="Send Message" onb2Click={onSendClick} css={styles}>
				<TextBoxIcon name="name" value={name} placeHolder="Enter Name" onChange={onInputChange}/>
				<TextBoxIcon name="email" value={email} icon={faUser} placeHolder="Enter Email id" onChange={onInputChange}/>
				<TextBoxIcon name="mobile" value={mobile} icon={faPhoneFlip} placeHolder="Enter Mobile Number" onChange={onInputChange}/>
				<TextArea label="Message" name="message" placeHolder="Enter your Message" value={message} r={3} onChange={onInputChange} />
			</LoginForm>
		</div>
	) ;
}

export default Contact ;
