import { Link } from 'react-router-dom' ;

import './downbar.css' ;

const DownBar = () => {

	return (
		<div className="downbar"> 
			<div className="downbar-left">
				<p> Copyright &copy; 2022 &ensp;|&ensp; Website Created by &emsp; </p>
				<a href="https://www.myarth.in" target="_blank" rel="noreferrer" >
					<img className="myarth" src='https://api.myarth.in/static/images/Logo/logo-black.png' alt="myarth" />
				</a>
			</div>
	        <div className="footer-links">
				<Link to="/privacy-policy"> Privacy Policy </Link>
				<p> | </p>
				<Link to="/refund-policy"> Refund Policy </Link>
				<p> | </p>
				<Link to="/shipping-policy"> Shipping Policy </Link>
				<p> | </p>
				<Link to="/terms-condition"> Terms & Conditions </Link>
			</div>
		</div>
	);
}

export default DownBar ;
