import './not.css' ;
import i04 from "./404.png" ;

const NotFound = () => {
	return (
		<div className="not-found-page">
			<img src={i04} alt="Not_Found" className="nf"/>  
			<div className="fl">
				<h1 className="f04"> 404 : Not Found ! </h1>
				<p className="taunt"> Sorry!, the page you were looking for Cannot be found on this server </p>
				<p className="taunt"> Unless, you were looking for this page... In which case you totally found it </p>
			</div>
		</div>
	);
}

export default NotFound ;