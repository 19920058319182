import './Display.css' ;

const DisplayDetailed = ({lidata, ulText, title}) => {
	const createLi = () => {
 		return lidata.map( (li,i) => {
 			if(li.startsWith('html'))
			{	li = li.replace('html','') ;
				return <li className="list-li" key={i} dangerouslySetInnerHTML={{ __html: li}} /> ;
 			}
			else 		
 				return <li className="list-li" key={i}> {li} </li> ; 
 		}) ;
 	}

	return (
		<div className="display-detail">
			<h2 className="display-title"> {title} </h2>
			<div className="list">
				<ul> {ulText}
					{createLi()}
				</ul>
			</div>
		</div>
	) ;
}

export default DisplayDetailed ;