import './text.css' ;

const TextArea = ({onChange, value, r, name, placeHolder}) => {
	return (
		<div className="text-div textarea-div">
        	<textarea className="inpu" name={name} rows={r} onChange={onChange} value={value} placeholder={placeHolder}/>
		</div>
	);
}

export default TextArea ;
