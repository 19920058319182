import './banner.css' ;

const Banner = ({imgLink, children}) => {
	return (
		<div className="banner" style={{ backgroundImage: `url(${imgLink})` }}>
			<div className="banner-children">
				{children}
			</div>
		</div>
	) ;
}

export default Banner ;