import {useEffect, useState, Fragment} from 'react' ;
import {Link} from 'react-router-dom' ;
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import NumberInput from './inputs/NumberInput.js' ;
import DateInput from './inputs/DateInput.js' ;
import FileInput from './inputs/FileInput.js' ;
// import ImageInput from './inputs/ImageInput.js' ;
// import MultiImageInput from './inputs/MultiImageInput.js' ;
import Range from './inputs/Range.js' ;
import DropDown from './inputs/DropDown.js' ;
import ColorInput from './inputs/ColorInput.js' ;
import Text from './inputs/Text.js' ;
import TextArea from './inputs/TextArea.js' ;
import Btn from './inputs/Btn.js' ;
import IconBtn from './inputs/IconBtn.js' ;
import Captcha from './inputs/Captcha.js' ;
import ObjectInput from './inputs/ObjectInput.js' ;
import ArrayInput from './inputs/ArrayInput.js' ;
import TagInput from './inputs/TagInput.js' ;
import SearchInput from './inputs/SearchInput.js' ;
import CheckBox from './inputs/CheckBox.js' ;

import './form.css' ;

const BasicForm = ({initData, onClick, data, errors, arr={}, isCaptcha=false, inputChangeOverRide=null}) => {
	const [userData, setUserData] = useState(initData) ;
	const [captchaValue, setCaptchaValue] = useState('') ;

	useEffect( () => {
		setUserData(initData) ;
	}, [initData]) ;

	const createElement = element => {
		if(element) {
			const {type, name} = element ;
	 
			const obj={data:element, onInputChange, value:userData[name], error:errors[name], handleEnter} ;

			const formComps = {
				text: <Text {...obj} />,
				textArea: <TextArea {...obj} />,
				password : <Text type = "password" {...obj} />,
				disabled : <Text {...obj} disabled={true}/>,
				plainText : <Text {...obj} plaintext={true}/>,
				link: <Link to={element.to} className={element.style}>{name}</Link>,
				btn: <Btn data={element} userData={userData} onClick={onClick[name]} isCaptcha={isCaptcha} captcha={captchaValue}/>,
				iconBtn: <IconBtn data={element} userData={userData} onClick={onClick[name]} isCaptcha={isCaptcha} captcha={captchaValue}/>,
				file : <FileInput data={element} onInputChange={onFileChange} error={errors[name]} />,
				multiFile : <FileInput data={element} onInputChange={onFileChange} error={errors[name]} multiple={true}/>,
				// image : <ImageInput image={userData[name]} data={element} onInputChange={onFileChange} error={errors[name]} setFile={setFileData}/>,
				// multiImage : <MultiImageInput images={userData[name]} data={element} onInputChange={onFileChange} error={errors[name]} setFile={setFileData}/>,
				color: <ColorInput {...obj}/>,
				dropdown : <DropDown {...obj}  />,
				range: <Range {...obj} />,
				date: <DateInput {...obj} />,
				number: <NumberInput {...obj} />,
				captcha: <Captcha data={element} onInputChange={onCaptchaChange} error={errors[name]} />,
				array: <ArrayInput element={element} onInputChange={setFileData} value={userData[name]}/>,
				object: <ObjectInput element={element} onInputChange={setFileData} value={userData[name]}/>,
				object2: <ObjectInput element={element} onInputChange={setFileData} value={userData[name]} narrow={true} />,
				tags: <TagInput data={element} onInputChange={setFileData} tags={userData[name]} />,
				search: <SearchInput arr={arr[name]} data={element} onInputChange={setFileData} value={userData[name]} multiple/>,
				searchOnly: <SearchInput arr={arr[name]} data={element} onInputChange={setFileData} value={userData[name]}/>,
				checkbox: <CheckBox data={element} onInputChange={setFileData} value={userData[name]} />,
			} ;

			return formComps[type] ;
		}
	}

	const onInputChange = event => {
		if(inputChangeOverRide)
			inputChangeOverRide({...userData, [event.target.name] : event.target.value})
		else
			setUserData({...userData, [event.target.name] : event.target.value}) ;
	}

	const onCaptchaChange = value => setCaptchaValue(value) ;

	const handleEnter = event => {
		if(event.which === 13)
			onClick[Object.keys(onClick)[0]](userData) ;
	}

	const onFileChange = event => {
		const {files} = event.target ;
		
		if(files && files.length > 0)
			setUserData({...userData, [event.target.name] : files}) ;
		else
			setUserData({...userData, [event.target.name] : []}) ; 
	}

	const setFileData = (name, obj) => {
		if(inputChangeOverRide)
			inputChangeOverRide({...userData, [name]: obj })
		else
			setUserData({...userData, [name]: obj }) ;
 	}

	if(window.screen.availWidth > 923)
		return (
			<Form noValidate >
				{	data.map( (one, i) => {
						if(one && one.length > 0)
							return (<Row className="mb-3" key={i}> 
								{one.map((two, i) => <Fragment key={i}>{createElement(two)}</Fragment> )} 
							</Row>)
						else
							return null ;
					}) 
				}
			</Form>
		) ;
	else
		return (
			<Form noValidate>
			{	data.flat().map((one,i)=><Row className="mb-2" key={i}>{createElement(one)}</Row>)   }
			</Form>
		) ;
}

export default BasicForm ;