import React from 'react' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;

import './textboxicon.css' ;

const TextBoxIcon = ({password, name, onChange, value, placeHolder, icon=null}) => {
	return (
		<div className = 'text-box-icon'>
			<input className = "tb-input" type={password?"password":"text"} name={name} onChange={onChange} value={value} placeholder={placeHolder} autoComplete="off" />
			{icon?<FontAwesomeIcon className="tb-icon" icon={icon} />:null}
		</div> 
	) ;
}

export default TextBoxIcon ;