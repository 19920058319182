import {Link} from 'react-router-dom' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;
import { faChevronRight } from '@fortawesome/free-solid-svg-icons' ;

import './breadcrum.css' ;

// current page ka path bata raha hai.
const BreadCrum = ({pathArr}) => {
	return (
		<div className="breadCrum">
			{pathArr.map((one, i) => {
				return (
					<Link key = {i} to={one[1]}>
						{one[0]}
						&nbsp; 
						{ (i===pathArr.length-1)?null:<FontAwesomeIcon icon={faChevronRight} /> }
						&nbsp; 
					</Link> 
				)  }
			)}
		</div>
	) ;
}

export default BreadCrum ;
