import {useState} from 'react' ;
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import Text from './Text.js' ;
import TextArea from './TextArea.js' ;

const ArrayInput = ({element, onInputChange, value=[]}) => {
	const [data, setData] = useState(value) ;
	const {name, label, descr, text, variant} = element ;

	const onTextChange = ({target}) => {
		let sno = parseInt(target.name.split(text)[1])-1 ;
		let temp = [...data] ;
		temp[sno] = target.value ;
		setData(temp) ;
		onInputChange(name, temp) ;
	}

	return (
		<Col md>
			<p>{label}</p>
			{
				data.map( (o,i) => {
					return (
						<Row className="mb-3 arrayInput" key={i}>
							{
								variant==='textArea'?
								<TextArea data = {{name: `${text}${i+1}`, label: `Enter ${text}${i+1}`, id: `${text}${i+1}`}} onInputChange={onTextChange} value={data[i]} />:
								<Text data = {{name: `${text}${i+1}`, label: `Enter ${text}${i+1}`, id: `${text}${i+1}`}} onInputChange={onTextChange} value={data[i]} />	
							}
							<Button className="npHeaderBtn" onClick={() => {
								let arr = data.filter((one, j)=> j!==i) ;
						    	setData(arr) ;
								onInputChange(name, arr) ;
						    }}> X </Button>
						</Row>
					) ;
				})
			}
		    <Button className="npHeaderBtn" onClick={() => setData([ ...data, ''])}> + Add {text} </Button>
		    {(descr)?<p> {descr} </p>:null}
		</Col>
	) ;
}

export default ArrayInput ;