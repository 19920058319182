import {Link} from 'react-router-dom' ;

import './loginform.css' ;

const LoginForm = ({b1, b1type, onb1Click, to, b2, b2type, onb2Click, heading, title, children, error = '', css = ''}) => {

	const checkB1 = () => {
		if(b1)	{
			if(b1type === 'link')
				return <Link className="cart-btn" to={to}>{b1}</Link>
			else
				return <button className="cart-btn" onClick={onb1Click}>{b1} </button> 	
		}
		else
			return null ;
	}

	const checkB2 = () => {
		if(b2)	{
			if(b2type === 'link')
				return <Link className="cart-btn" to={to}>{b2}</Link>
			else
				return <button className="cart-btn" onClick={onb2Click}>{b2} </button> 	
		}
		else
			return null ;
	}

	const returnErrorText = () => {
		if(error !== '')
			return <h5 className="error">{error}</h5>
	}

	return (
		<div className = {`login-form ${css}`}>
			<fieldset className="box">
				<legend className="box-title"> &nbsp;{title}&nbsp; </legend>
				<h3 className="heading">{heading}</h3>
				{children}
				<div className="buttons"> {checkB1()} {checkB2()} </div>
				{returnErrorText()}
			</fieldset>
		</div>
	);
}

export default LoginForm ;
