import Slider from '../slider/Slider.js' ; 
import ProductList from '../products/ProductList.js' ;
import './home.css' ;

import curpro from '../images/cupro-slider2.webp' ;
// import gc from '../images/gc-slider.webp' ;
// import gt from '../images/gt-slider.webp' ;

const sliderData = [{
	name : "Curpro" ,
	packing: "5 Sachets",
	price: 150,
	image: "https://api.myarth.in/static/images/orn/webp/curproS1.webp",
	banner: curpro,
	sno: 41, 
	stock: "Yes",
}
// , {
// 	name: "Grevim GC (Green Coffee)",
// 	image: "https://api.myarth.in/static/images/orn/webp/gc4.webp",
// 	packing: "25 Sachets",
// 	price: 625,
// 	banner: gc,
// 	sno: 36,
// 	stock: "No",
// }, {	
// 	name: "Grevim GT (Green Tea)",
// 	packing: "6 Sachets",
// 	price: 72,
// 	image: "https://api.myarth.in/static/images/orn/webp/gt4.webp",
// 	banner: gt,
// 	sno: 13,
// 	stock: "No",
// }
] ;

const Home = ({ slider = '' }) => {

	const returnSlider = () => {
		if(slider !== 'no')
			return <Slider width="100" arr={sliderData} autoplay="on" interval={10000}/> ;
	}

	return(
		<div className="home">
            {returnSlider()}
            <ProductList />
		</div>
	) ;
}

export default Home ;