import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;

const IconBtn = ({data, userData, onClick, captcha, isCaptcha = false}) => {
	const {name, style, icon} = data ;

	if((isCaptcha && captcha.length > 1) || !isCaptcha)
		return <Button className={style} onClick={() => onClick(userData)}><FontAwesomeIcon icon={icon} />{name}</Button> ;
	else return null ;
}

export default IconBtn ;