import { useState, useEffect, useContext } from 'react' ;
import { faUser, faPhoneFlip, faLocationDot } from '@fortawesome/free-solid-svg-icons' ;

import Redirect from '../redirect/Redirect.js' ;
import { addNotif, remNotif } from '../notif.js' ;
import { invalidEmail, invalidMobile, invalidName } from '../valid.js' ;
import LoginForm from '../forms/LoginForm.js' ;
import TextBoxIcon from '../textboxicon/TextBoxIcon.js' ;
import {ProductDataContext} from '../../context/ProductData.js' ;
import {UserContext} from '../../context/UserContext.js' ;
import {CartContext} from '../../context/CartContext.js' ;

import './checkout.css' ;

const initDetails = {
	name : '',
	email: '',
	mobile: '',
	address: '',
} ;

const Checkout = () => {	
	const [details, setDetails] = useState(initDetails) ;
	const [error, setError] = useState('') ;
	const {user} = useContext(UserContext) ;
	const {cart, empty} = useContext(CartContext) ;
	const {data} = useContext(ProductDataContext) ;
	
	useEffect(() => {
		const {name, email, mobile, address} = user ;
		setDetails({ ...details, name, email, mobile, address}) ;
		//eslint-disable-next-line
	}, []) ;

	const returnTotal = () => {
		const sum = cart.map(one=> {
			if(one) 
				return data[one.sno].price*one.qty ;
			else 
				return null ;
		}).filter(two => two).reduce( (a, b) => a+b, 0 )
		
		if(sum < 500)
			return sum+75 ;
		else 
			return sum ;
	}

	const placeOrder = () => {
		const {name, email, mobile, address} = details ;

		if(error !== '')
			setError('You must fix all errors before proceeding');
		else
		{	
			if(invalidName(name))
				setError(invalidName(name)) ;
			else if(invalidEmail(email))
				setError(invalidEmail(email)) ;
			else if(invalidMobile(mobile))
				setError(invalidMobile(mobile)) ;
			else if( address === '' )
				setError('You must select an Address') ;
			else {  	
				fetch('https://api.myarthhardware.com/order',{
					method : 'post' ,
					body : JSON.stringify({name, mobile, email, address,
							total: returnTotal(), products: cart}),
					headers : { 'Content-Type' : 'application/json' }
				})
				.then(res => {
					if(res.ok)
						return res.json() ;
					else
						throw Error(res.statusText) ;
				})
				.then(data => {	
					setDetails(initDetails) ;
					empty() ;
					remNotif() ;
					addNotif('Successfully Placed Order', 'success') ;
				}) 
				.catch( err  => {
					console.log(err) ; 
					remNotif() ;
					addNotif('Error Placing Order', 'error') ;	
				}) ;
			}
		}
	}

	const onInputChange = (event) => {
		setDetails({ ...details, [event.target.name] : event.target.value}) ;
		setError('') ;
	}

	// const getPaymentData = (dataToSend) => {
	// 	// fetch('https://api.myarthhardware.com/payment' ,{
	// 	fetch('https//localhost:8000/payment' ,{
	// 			method : 'post' ,
	// 			headers : { 'Content-Type' : 'application/json'} ,
	// 			body : JSON.stringify( dataToSend ) ,
	// 		})
	// 		.then(res => {
	// 			if(res.ok)
	// 				return res.json() ;
	// 			else
	// 				throw Error(res.statusText) ;
	// 		})
	// 		.then(data => {	
	// 			// this.setState({data: initPerson});
	// 			// remNotif() ;
	// 			// addNotif('Successfully Registered', 'success') ;

	// 			// this.props.loadUser(data) ;
	// 		}) 
	// 		.catch( err  => {
	// 			console.log(err) ;
	// 			remNotif() ;
	// 			addNotif('Error getting Payment Details' , 'error') ;
	// 		}) ;
	// }

	// const makePayment = () => {
	// 	console.log('Making Payment') ;
	// 	const {name, email, mobile, address} = details ;

	// 	getPaymentData({name, mobile, email, address, amount: returnTotal(), products: cart}) ;
	// }

	if(user.name)
		if(cart.filter(one => one).length > 0)		{
			const {name, email, mobile, address} = details ;
			return (
				<div className="checkout">
					<div className = "checkout-top">
						<LoginForm title="Enter Details " error={error} >
							<TextBoxIcon name="name" value={name} placeHolder="Enter Name" onChange={onInputChange}/>
							<TextBoxIcon name="email" value={email} icon={faUser} placeHolder="Enter Email id" onChange={onInputChange}/>
							<TextBoxIcon name="mobile" value={mobile} icon={faPhoneFlip} placeHolder="Enter Mobile Number" onChange={onInputChange}/>
							<TextBoxIcon name="address" value={address} icon={faLocationDot} placeHolder="Enter Address" onChange={onInputChange}/>
						</LoginForm>
					</div>
					<div className = "checkout-bottom">
						<button className="cart-btn" onClick={placeOrder} > Place Order </button>
					</div>
					{/*
						<h2> Select Payment method </h2>	<hr />
						<button className="cart-btn" onClick={makePayment}> Make Payment </button>
						<p className="checkout-warning"> Currently, we are not accepting online payments and payment can only be made at the time of delivery through Cash or E-Wallets 
						</p>
					*/}
				</div>
			) ;
		}
		else
			return <Redirect to = '/' /> ;
	else
		return <Redirect to = '/login' />
}

export default Checkout ;