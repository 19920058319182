import DisplayDetailed from '../display/DisplayDetailed.js' ;
import Title from '../title/Title.js' ;

import './about.css' ;

const basicData = ['Respiratory Allergy products', 'Future Immunity solutions', 'Pain Management therapies', 'ENT problems and Therapies' ] ;

const basicData2 = ['M/S Maheshwari Pharma Ltd. (Ayurvedic)', 'M/S Stepan Labs Pvt. Ltd. (Dietary)', 'M/S Shri Hari Herbals (Ayurvedic)', 'M/S Precept Pharma Ltd. (Allopathic MDI range)', 'M/S Upkar Pharmaceuticals (Allopathic)' ] ;

const cin = "U24233 DL2005PTC 140854" ;
const gstin = "07AAACO7790A1ZJ" ; 
const dlno = "123771-20B, 123772-21B"
const address = "Unit No. 4, First floor, Pocket B&C, CSC, Phase 4, Ashok Vihar, Delhi-110052" ;
const contact = "011-27303025, 9312245125" ;
const directors = "Shri A.P. Aggarwal, Ashish Aggarwal(9212155125)" ;
const bank = "ICICI Bank, Ashok Vihar, Delhi" ;
const accountno = "033105004178" ;
const ifsc = "ICIC0000331" ; 

const About = () => {
	return (
		<div className="about">
			<Title name="About Us"  arr={[[ 'Home', '/'], ['About Us', '/about']]} />
			<div className="inner-about">
				<p>ORN Remedies Pvt. Ltd. started its journey in 1999 by the name of ORION Remedies. The name ORION drew inspiration from the constillation Orion(Mrigashira Nakshatra) which means a hunter or fighter in its true spirit. </p>
				<p>Coming through the initial challenges, we took steps towards launching our own product range with products - ORITUS, LIGAND and AURA. </p>
				<p>We commissioned ourselves as a company in October 2006 by the name "ORN Remedies Pvt. Ltd." with corporate office at Ashok Vihar, Delhi. Our dedicated full time independent marketing operations started in 2012. ORN Remedies is operational in Delhi NCR region.  </p>
				<DisplayDetailed title="" lidata={basicData} ulText="Our focus operational areas are:"/>
				<p>We have a dedicated marketing team of 8 personnel and a support backend team of 5 at present. We get our products on third-party basis in some of the best manufacturing facilities </p>
				<DisplayDetailed title="" lidata={basicData2} ulText="We enjoy a long and healthy manufacturing association with:"/>
				<p>We have a product portfolio of 31 products and a rich future pipeline of products in development. We are an ISO 9001:2015 Certified company.</p>
				<p>Our Bankers are : <br/> 
					 {bank} <br/> 
					<strong>A/C No. :</strong> {accountno} <br/>
					<strong>IFSC Code :</strong> {ifsc}
				</p>
				<p>Our other details : <br/>
					<strong>CIN :</strong> {cin} <br/>
					<strong>GSTIN :</strong> {gstin} <br/>
					<strong>DL No. :</strong> {dlno} <br/>
					<strong>Address :</strong> {address}<br/>
					<strong>Contact No. :</strong> {contact} <br/>
					<strong>Directors :</strong> {directors} <br/>
				</p>
			</div>
		</div>
	);
}

export default About ;