import './title.css' ;

import Banner from '../banner/Banner.js' ;
import BreadCrum from '../breadcrum/BreadCrum.js' ;
import imageBanner from '../images/parallax.jpg' ;

const Title = ({name, arr}) => {
	return (
		<div className="Title">
			<Banner imgLink={imageBanner}>
				<h4 className="title-name"> {name} </h4>
				<BreadCrum pathArr={arr} />
			</Banner>
		</div>
	) ;
}

export default Title ;