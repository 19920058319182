import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;
import { faSquareCheck, faSquare} from '@fortawesome/free-solid-svg-icons' ;

const CheckBox = ({data, onInputChange, value}) => {
	const {name, label} = data ;
	const icon = value?faSquareCheck:faSquare ;

	return (
		<Col md>
			<div className="check-con">
				<Button className="check-btn" onClick={() => onInputChange(name, !value)}>
					<FontAwesomeIcon icon={icon}/>
				</Button>
				<p className="check-label">{label}</p>
			</div>
		</Col>
	) ;
}

export default CheckBox ; 