import { useState, useContext } from 'react';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons' ;

import Redirect from '../redirect/Redirect.js' ;
import { addNotif, remNotif} from '../notif.js' ;
import { invalidEmail, isBlank } from '../valid.js' ;
import {UserContext} from '../../context/UserContext.js' ;
import LoginForm from '../forms/LoginForm.js' ;
import TextBoxIcon from '../textboxicon/TextBoxIcon.js' ;
import './login.css' ;

const initData = { email: '', password: ''} ;

const Login = () => {
	const [mode, setMode] = useState('normal') ;
	const [data, setData] = useState(initData) ;
	const [error, setError] = useState('') ;
	const {user, loadUser} = useContext(UserContext) ;

	const sendLoginRequest = () => {
		const {password, email} = data ;

		addNotif('Please Wait...') ;

		fetch('https://api.myarthhardware.com/login',{
			method : 'post' ,
			headers : { 'Content-Type' : 'application/json'} ,
			body :JSON.stringify({password, email}) ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then(data =>{
			setData(initData) ;
			remNotif() ;
			addNotif('Successfully Logged In', 'success') ;
			loadUser(data) ;
		})  
		.catch( err  => {
			console.log(err) ;
			remNotif() ;
			addNotif('Error Logging in', 'error') ;	
			setError('Incorrect Username OR Password') ;
		}) ;
	}

	const onSendReqClick = () => {
		addNotif('Please Wait...') ;

		fetch('https://api.myarthhardware.com/forgot',{
			method : 'post' ,
			headers : { 'Content-Type' : 'application/json'} ,
			body : JSON.stringify({email: data.email}) ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then(data =>{	
			remNotif() ;
			addNotif('Request sent for password reset', 'success') ;
		})  
		.catch( err  => {
			console.log(err) ;
			remNotif() ;
			addNotif('E-Mail invalid', 'error') ;	
			setError('E-Mail doesn\'t exist in database') ;
		}) ;
	}

	const onLoginClick = () => {
		const {password, email} = data ;
		
		if(error !== '')
			setError('You must fix all errors before proceeding') ;
		else
		{
			if( invalidEmail(email) )
				setError(invalidEmail(email)) ;
			else if ( isBlank(password, 'Password') )
				setError(isBlank(password, 'Password')) ;
			else
			  	sendLoginRequest() ;
		}
	}

	const onInputChange = (event) => {
		setData({...data, [event.target.name] : event.target.value}) ;
		setError('') ;
	}

	const createLogin = () => {
		const {password, email} = data ;

		return (
			<div>	
				<LoginForm heading=" Login " error={error} b1="Register" b1type="link" to="/register" b2="Login" onb2Click={onLoginClick}>
					<TextBoxIcon name="email" value={email} icon={faUser} placeHolder="Enter Email id" onChange={onInputChange}/>
					<TextBoxIcon name="password" value={password} icon={faKey} placeHolder="Enter Password" password onChange={onInputChange}/>
					<p className="fp" onClick={()=>setMode('fp')}> Forgot Password ? </p>
				</LoginForm>
			</div>
		) ;
	}

	const resetPassword = () => {
		return (
			<div>	
				<LoginForm heading=" Reset Password " error={error} b1="Go Back" b2="Send Request" onb1Click={()=>setMode('normal')} onb2Click={onSendReqClick}>
					<TextBoxIcon name="email" value={data.email} icon={faUser} placeHolder="Enter Email id" onChange={onInputChange}/>
				</LoginForm>
				<p className="nfp"> <strong>*Note: </strong>If you enter an E-Mail that exists in our database then you will recieve a mail containing your new password. You can re-change your password once you log back in. </p>
			</div>
		) ;
	}

	const checkMode = () => {
		switch(mode)
		{	case 'normal': return createLogin() ;
			case 'fp' : return resetPassword() ;
			default : return 'You probably encountered a problem' ;
		}
	}

	if(user.name)
		return <Redirect to='/' />
	else
		return <div className="loginpage"> {checkMode()} </div> 
}

export default Login ;
