import React, {createContext, useState} from 'react' ;

const UserContext = createContext() ;

const UserContextProvider = ({children}) => {
	const [user, setUser] = useState(localStorage.getItem('ornUser')?JSON.parse(localStorage.getItem('ornUser')):{}) ; 
	const [userToken, setUserToken] = useState(localStorage.getItem('ornUserToken')?localStorage.getItem('ornUserToken'):'') ;

	const loadUser = (user) => {
		if(user.user) {
			setUser(user.user) ;
			setUserToken(user.token) ;
			localStorage.setItem('ornUser', JSON.stringify(user.user) );
			localStorage.setItem('ornUserToken', user.token);
		}	
		else {
			setUser({}) ;
			setUserToken('') ;
			localStorage.clear() ;
		}
	}

	return (
		<UserContext.Provider value={ { user, userToken, loadUser } }>
			{children}
		</UserContext.Provider>
	) ;
}

export {UserContext, UserContextProvider} ;