import React from 'react' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons' ;

import './counter.css' ;

const Counter = ({num, onUp, onDown}) => {
	return (
		<div className="counter-con">
			<p className="counter-num"> {num} </p>
			<div className="counter-arrows">
				<FontAwesomeIcon icon = {faAngleUp} onClick={onUp}/>
				<FontAwesomeIcon icon = {faAngleDown} onClick={onDown}/>
			</div>
		</div>
	) ;
}

export default Counter ;