import {useContext} from 'react' ;
import {Link} from 'react-router-dom' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;
import { faCartShopping, faTrashCan } from '@fortawesome/free-solid-svg-icons' ;

import {ProductDataContext} from '../../context/ProductData.js' ;
import {UserContext} from '../../context/UserContext.js' ;
import {CartContext} from '../../context/CartContext.js' ;
import Counter from '../counter/Counter.js' ;
import Redirect from '../redirect/Redirect.js' ;
import './cartpage.css' ;

const CartPage = () => {
	const {user} = useContext(UserContext) ;
	const {cart, inc, dec, empty, remove} = useContext(CartContext) ;
	const {data} = useContext(ProductDataContext) ;
	
	const returnTotal = () => {
		const sum = cart.map(one=> {
			if(one) 
				return data[one.sno].price*one.qty ;
			else 
				return null ;
		}).filter(two => two).reduce( (a, b) => a+b, 0 )
		
		if(sum < 500)
			return sum+75 ;
		else 
			return sum ;
	}

	const generateProducts = () => {
		return cart.map((one, i) => {
			if(one) {
				const {qty, sno} = one ;
				const {images, name, price} = data[sno] ;
				return (
					<div className="cartpage-product" key={i}>
						<div className="cartpage-image" style={{backgroundImage:'url('+images[0]+')'}}></div>
							<div className="cartpage-product-middle">
							<p className="cartpage-product-name"> {name} </p>
							<div className="cp-del-con">
								<p> &#8377;&nbsp;{price}&nbsp;/-&nbsp; </p>
								<Counter num={qty} onUp={()=>inc({sno})} onDown={()=>dec({sno})} />
								<button className="cart-btn cart-del-btn" onClick={()=>remove({sno})}> <FontAwesomeIcon icon={faTrashCan} />&ensp;Delete </button> 
							</div>
						</div>
						<p className="cartpage-product-price">
							&#8377;&nbsp;{price*qty}&nbsp;/-
						</p>
					</div>
				) ;
			}
			else 
				return null ;
		}) ;
	}

	if(user.name)
		if(cart.filter(one => one).length > 0)
		{	
			return(
				<div className="cartpage">
					<div className="cartpage-left">
						<h2> <FontAwesomeIcon icon={faCartShopping} /> SHOPPING CART </h2>
						<div className="cp-left-scroll"> {generateProducts()} </div>
						<div className="cp-left-bottom">
							<button className="cart-btn cart-del-btn" onClick={empty}> Empty Cart </button>
							<h2 className="cartpage-total">Cart Total : &#8377;&nbsp;{returnTotal()}&nbsp;/-</h2>
						</div>
					</div>
					<div className="cartpage-right">
						<div className="cp-right">
							<h3> Order Summary </h3>
							<hr/>
			            	{
			            		[ 
				            		['Order Amount:',returnTotal()-(returnTotal()>574?0:75)],
				            		['Taxes:','Included'],
				            		['Delivery Charge:',(returnTotal()>574?0:75)],
				            		['Total:',returnTotal()]
			            	 	].map((one,i) =>{ 
			            	 		return(
		            	 				<div className="order-sum" key={i}>
					            	 		<p>{one[0]}</p>
					            	 		<p className="order-sum-p">{one[1]}</p>
	            	 					</div> 
	            	 				)}
			            	 	) 
			            	}					
							<Link className="cart-btn cp-ptc" to="/checkout"> Proceed to Checkout </Link>
			            </div>
					</div>
				</div>
			) ;
		}
		else
			return <Redirect to = '/' />
	else
		return <Redirect to = '/login' />
}

export default CartPage ;