import { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons' ;

import Card from '../card/Card.js' ;
import {ProductDataContext} from '../../context/ProductData.js' ;
import './product.css' ;

const ProductList = () => {
	const {data} = useContext(ProductDataContext) ;
	const [search, setSearch] = useState('') ;

	const filterRecords = (product) => {
		return product && product.name.toLowerCase().includes(search.toLowerCase()) ;
	}

	const createProductCards = () => {
		return data.filter(filterRecords).map((product,i)=><Card key={i} data={product} />) ;
	}

	const onSearchChange = (event) => setSearch(event.target.value) 

	return (
		<div className="product-list">
			<div className="product-search">
				<div className="search-con">
					<input type="text" name="search" value={search} placeholder="Search Products" onChange={onSearchChange}/>
					<FontAwesomeIcon icon={faMagnifyingGlass} />
				</div>
			</div>
			<div className="actual-list"> {createProductCards()} </div>
		</div>
	);
}

export default ProductList ;
