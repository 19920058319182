import {formatDate, formatAddress, formatOrders} from './format.js' ;

const userColumns = [
	{ name: 'name'}, { name: 'email'}, { name: 'mobile'}, { name: 'createdAt', format: formatDate},  
] ;

const userDisplay = [
	{ name: 'name', title: 'Name'}, { name: 'email', title: 'E-Mail'}, 
	{ name: 'mobile', title: 'Mobile No.'}, 
	{ name: 'createdAt', title: 'Register Date', format: formatDate}, 
	{ name: 'address', title: 'Address'}, {name: 'address2', title: ' ', format: formatAddress},
	{ name: 'orders', title: 'Orders', format: formatOrders},
] ;

const onDeleteClick = () => {
	console.log('deleted') ;
}

const onSwitchClick = (event) => {
	console.log(event.target) ;
}

const activeCust = {
	columns: userColumns, 
	actions: {
		before: ['sno', 'checkbox'], 
		after: ['switch', 'view', 'delete'],
		search: ['name', 'email', 'mobile'],
		switch: onSwitchClick,
		delete: onDeleteClick
	},
	display: userDisplay,
} ;

export { activeCust } ;