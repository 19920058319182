import {useState, useEffect, Fragment} from 'react' ;
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import Text from './Text.js' ;

const modify = (obj) => {
	const newObj = {} ;
	Object.keys(obj).forEach((one, i) => {
		newObj[`feature${i}`] = one ;
		newObj[`feature${i}Value`] = obj[one]
	}) ;
	return newObj ;
}

const ObjectInput = ({element, onInputChange, value={}, narrow=false}) => {
	const [valueNum, setValueNum] = useState([...Array(Object.keys(value).length).keys()]) ;
	const [data, setData] = useState(modify(value)) ;
	const {name, label, descr, text} = element ;

	useEffect(()=>setData(modify(value)) ,[value])

	const cleanObj = (dirtyObj) => {
		let obj = {} ;
		Object.keys(dirtyObj).filter(one=>!one.includes('Value')).forEach( one => obj[dirtyObj[one]] = dirtyObj[one+'Value'])
		return obj ;
	}

	const onTextChange = e => {
		setData({...data, [e.target.name]: e.target.value }) ;
		onInputChange(name, cleanObj({...data, [e.target.name]: e.target.value }) )
	}

	return (
		<Col md>
			<p>{label}</p>
			{
				valueNum.map( (o,i) => {
					if(narrow)
						return (
						<Fragment key={i}>
						<Row className="mb-3">
							<Text data={{name: `feature${o}`, label: "Enter Feature Name", id: `feature${o}`}} onInputChange={onTextChange} value={data[`feature${o}`]} />
						</Row>
						<Row className="mb-3">
							<Text data = {{name: `feature${o}Value`, label: "Enter Feature Value", id: `featureValue${o}`}} onInputChange={onTextChange} value={data[`feature${o}Value`]} />
						</Row>
						</Fragment>
					) ;
					else
						return (
							<Row className="mb-3" key={i}>
								<Text data={{name: `feature${o}`, label: `Enter ${text} Name`, id: `feature${o}`}} onInputChange={onTextChange} value={data[`feature${o}`]} />
								<Text data = {{name: `feature${o}Value`, label: `Enter ${text} Value`, id: `featureValue${o}`}} onInputChange={onTextChange} value={data[`feature${o}Value`]} />
							</Row>
						) ;
				})
			}
		    <Button className="login-btn" onClick={() => {
		    	let str = `feature${valueNum.length}` ;
		    	setData({ ...data, [str]: '', [str+'Value']: ''}) ;
		    	setValueNum([...valueNum, valueNum.length]) ;
		    }}> + Add {text} </Button>
		    {(descr)?<p> {descr} </p>:null}
		</Col>
	) ;
}

export default ObjectInput ;