import ReCAPTCHA from "react-google-recaptcha";
import Col from 'react-bootstrap/Col';

const Captcha = ({onInputChange}) => {
	return (
		<Col md>
			<ReCAPTCHA sitekey="6Ld9t20fAAAAAF779x7Hf1FKBbPy40cZvH3z76hG" onChange={onInputChange} />
		</Col>
	) ;
}

export default Captcha ; 