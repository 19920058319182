import Title from '../title/Title.js' ;
import './distributors.css' ;

const ndname1 = "Kasturi Enterprises" ,  ndaddress1 = "Adarsh Nagar, Delhi (011-27682908, 9213730012)" ;
const ndname2 = "Golden Pharmaceutical" , ndaddress2 = "Shalimar Bagh, Delhi (011-27498133, 9810260414)"  ;
const ndname3 = "SR Distributors" , ndaddress3 = "Rohini, Delhi (011-27013089, 9810765450)" ;
const ndname4 = "Kailash Medilines" , ndaddress4 = "Mukherjee Nagar, Delhi (011-27651234, 9811445838)" ;
const ndname5 = "1mg HealthCare Solutions Pvt. Ltd." , ndaddress5 = "(011-43769900, 9990064886)" ;
const cdname1 = "A.B.Multi Pharma" , cdaddress1 = "Sarai Rohilla, Delhi, (011-23697272, 9811595304)" ;
const edname1 = "Neelkanth Drugs Pvt.Ltd." , edaddress1 = "Patparganj, Delhi (011-43057702, 011-43057777)" ;
const edname2 = "Raghu Enterprises" , edaddress2 = "Bholanath Nagar, Shahdara, Delhi, ( 011-22134170, 9810522523" ;
const odname1 = "Shradha Pharmaceutical" , odaddress1 = "Sant Nagar, Delhi, (9213757650)" ;

const Distributors = () => {
	return (
		<div className="distrib">
			<Title name="Distributors"  arr={[[ 'Home', '/'], ['Distributors', '/distributors']]} />
			<div className="inner-distrib">
				<div className="distrib-left">
					<div className="distrib-section">
						<ul>
							<h2>NORTH DELHI</h2>
							<li><strong>{ndname1}</strong>, {ndaddress1} </li>
							<li><strong>{ndname2}</strong>, {ndaddress2} </li>
							<li><strong>{ndname3}</strong>, {ndaddress3} </li>
							<li><strong>{ndname4}</strong>, {ndaddress4} </li>
							<li><strong>{ndname5}</strong>, {ndaddress5} </li>
						</ul>
					</div>
					<div className="distrib-section">
						<ul>
							<h2>CENTRAL DELHI</h2>
							<li><strong>{cdname1}</strong>,{cdaddress1}  </li>
						</ul>
					</div>
				</div>
				<div className="distrib-right">
					<div className="distrib-section">
						<ul>
							<h2>EAST DELHI</h2>
							<li><strong>{edname1}</strong>, {edaddress1}</li>
							<li><strong>{edname2}</strong>, {edaddress2}</li>
						</ul>
					</div>
					<div className="distrib-section">
						<ul>
							<h2>OUTER DELHI</h2>
							<li><strong>{odname1}</strong>, {odaddress1}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Distributors ;
