import React, {createContext, useState, useEffect} from 'react' ;

const CartContext = createContext() ;

const CartContextProvider = ({children}) => {
	const [cart, setCart] = useState([]) ;

	useEffect( () => {
		// fetch('https://api.myarthhardware.com/cart')
		// .then(res => {
		// 	if(res.ok)
		// 		return res.json() ;
		// 	throw Error(res.statusText) ;
		// })
		// .then( data => setProductData(data) ) 
		// .catch( err  => console.log(err) ) ;
	}, []) ;

	const addToCart = (product) => {
		let newCart = [...cart] ;
		if(!newCart[product.sno])
			newCart[product.sno] = product ;
		setCart(newCart) ;
	}

	const increaseQty = (product) => {
		let newCart = [...cart] ;
		newCart[product.sno].qty += 1;
		setCart(newCart) ;
	} 

	const removeFromCart = (product) => {
		let newCart = [...cart] ;

		if(newCart[product.sno])
			newCart[product.sno] = undefined ;
		
		setCart(newCart) ;
	}

	const decreaseQty = (product) => {
		let newCart = [...cart] ;
		if(newCart[product.sno].qty > 1)
			newCart[product.sno].qty -= 1;
		else if (newCart[product.sno].qty === 1)
			newCart[product.sno] = undefined ;
		setCart(newCart) ;
	}

	const emptyCart = () => setCart([]) ;

	const valueObj = { 
		cart, 
		add: addToCart, 
		remove: removeFromCart, 
		empty: emptyCart, 
		inc: increaseQty, 
		dec: decreaseQty
	} ;

	return (
		<CartContext.Provider value={valueObj}>
			{children}
		</CartContext.Provider>
	) ;
}

export {CartContext, CartContextProvider} ;