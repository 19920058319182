import { Fragment, useState, useEffect, useContext } from 'react';
import { faUser, faKey, faPhoneFlip, faLocationDot } from '@fortawesome/free-solid-svg-icons' ;

import Redirect from '../redirect/Redirect.js' ;
import { addNotif, remNotif } from '../notif.js' ;
import { invalidEmail, invalidMobile, invalidPass, isBlank, invalidName } from '../valid.js' ;
import LoginForm from '../forms/LoginForm.js' ;
import TextBoxIcon from '../textboxicon/TextBoxIcon.js' ;
import './register.css' ;
import {UserContext} from '../../context/UserContext.js' ;

const initPerson = {
	name: '' ,		email: '' ,		password: '',	repass: '',		
	mobile: '',		address: ''		
} ;

const Register = ({mode, edit}) => {
	const [data, setData] = useState(initPerson) ;
	const [error, setError] = useState('') ; 
	const {user, loadUser, userToken} = useContext(UserContext) ;

	useEffect( () => (mode === 'edit')?setData(user):setData(initPerson) , [user, mode]) ;

	const sendRegisterRequest = () => {
		if(mode === 'edit')
		{	addNotif('Please Wait...') ;

			fetch('https://api.myarthhardware.com/users/me',{
				method : 'PATCH' ,
				headers : { 'Content-Type' : 'application/json', 
							'Authorization' : `Bearer ${userToken}`} ,
				body :JSON.stringify(data) ,
			})
			.then(res => {
				if(res.ok)
					return res.json() ;
				else
					throw Error(res.statusText) ;
			})
			.then(data => {	
				remNotif() ;
				addNotif('Successfully Updated Profile', 'success') ;
				
				loadUser({user: data, token: userToken}) ;
				edit() ;
			}) 
			.catch( err  => {
				console.log(err) ;
				remNotif() ;
				addNotif('Error updating profile' , 'error') ;
			}) ;
		}
		else
		{	addNotif('Please Wait...') ;

			fetch('https://api.myarthhardware.com/users' ,{
				method : 'post' ,
				headers : { 'Content-Type' : 'application/json'} ,
				body :JSON.stringify({...data, address2: " "}) ,
			})
			.then(res => {
				if(res.ok)
					return res.json() ;
				else
					throw Error(res.statusText) ;
			})
			.then(data => {	
				setData(initPerson) ;
				remNotif() ;
				addNotif('Successfully Registered', 'success') ;

				loadUser(data) ;
			}) 
			.catch( err  => {
				console.log(err) ;
				remNotif() ;
				addNotif('Error while registration' , 'error') ;
			}) ;
		}
	}

	const onNextClick = () => {
		const {name, password, repass, email, mobile, address} = data ;
		
		if(error !== '')
			setError('You must fix all errors before proceeding') ;
		else
		{
			if( invalidEmail(email) )
				setError(invalidEmail(email)) ;
			else if(invalidName(name) )
				setError(invalidName(name)) ;
			else if (mode !== 'edit' && invalidPass(password, repass) )
				setError(invalidPass(password, repass)) ;
			else if (invalidMobile(mobile) )
				setError(invalidMobile(mobile)) ;
			else if (isBlank(address, 'Address') )
				setError(isBlank(address, 'Address')) ;
			else
			  	sendRegisterRequest() ;
		}
	}

	const checkEditMode = () => {
		if(mode !== 'edit')
			return (
				<Fragment>
					<TextBoxIcon name="password" value={data.password} icon={faKey} placeHolder="Enter Password" password onChange={onInputChange}/>
					<TextBoxIcon name="repass" value={data.repass} icon={faKey} placeHolder="Re-Enter Password" password onChange={onInputChange}/>
				</Fragment>
			) ;
	}

	const onInputChange = (event) => {
		setData({...data, [event.target.name] : event.target.value}) ;
		setError('') ;
	}

	const registerForm = () => {
		const {name, email, mobile, address} = data ;

		return (
			<div>	
				<LoginForm title="Enter Details " error={error}
					b2={(mode==='edit')?"Update":"Register"} onb2Click={onNextClick} >
					<TextBoxIcon name="name" value={name} placeHolder="Enter Name" onChange={onInputChange}/>
					<TextBoxIcon name="email" value={email} icon={faUser} placeHolder="Enter Email id" onChange={onInputChange}/>
					<TextBoxIcon name="mobile" value={mobile} icon={faPhoneFlip} placeHolder="Enter Mobile Number" onChange={onInputChange}/>
					<TextBoxIcon name="address" value={address} icon={faLocationDot} placeHolder="Enter Address" onChange={onInputChange}/>
					{ checkEditMode() } 
				</LoginForm>
			</div>
		) ;
	}
	
    if (mode === 'edit')
		return <div>{registerForm()}</div> ;
	else
	{	if(user.name)
			return <Redirect to='/' />
		else
			return <div className="su-blue-bg register"> {registerForm()} </div>
	}
}

export default Register ;