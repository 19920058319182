import Title from '../title/Title.js' ;
import DisplayDetailed from '../display/DisplayDetailed.js' ;

const PolicyPage = ({name, title, arr, lidata}) => {
	return (
		<div className="policy-page">
			<Title name={name}  arr={arr} />
			<DisplayDetailed title={title} lidata={lidata} />
		</div>
	) ;
}

const refund = [
'All payments are made before placing an order, only on successful completion of payment, will the order be placed.',
'Order can be cancelled only by contacting ORN Remedies through Phone or Email, no provision of cancellation is provided on this website.',
'No Refund/Cancellation of the payment is allowed, once the order has been dispatch',
'htmlFor any kind of grievance or queries related to Payments / Refund / Cancellation, write to us at: <b>info@ornremedies.com</b>'
] ;

const RefundPolicy = () => <PolicyPage arr={[[ 'Home', '/'], ['Refund Policy', '/refund-policy']]} name="Refund/Cancellation Policy" title="ORN Remedies Refund/Cancellation Policy" lidata={refund} />

const shipping = [
'All deliveries in Delhi-NCR Region would be doorstep deliveries. All deliveries outside Delhi-NCR Region would be done through courier services like eKart, DTDC, etc.',
'Normally, shipping takes upto 48 hours(for Delhi NCR). For Other parts of India, delivery time is subject to courier services and may take upto 7 working days (for remote areas of India)',
'All deliveries will be made only in India, no delivery outside India is provided.',
'If goods are damaged during courier transit, it is not the responsibility of ORN Remedies to provide Refund/Replacement'
] ;


const ShippingPolicy = () => <PolicyPage arr={[[ 'Home', '/'], ['Shipping Policy', '/shipping-policy']]} name="Shipping Policy" title="ORN Remedies Shipping Policy" lidata={shipping} />

const tc = [
'htmlThis site and the information, names, images, pictures, logos regarding or relating to ORN Remedies are provided &quot;as is&quot; without any representation or endorsement made and without warranty of any kind whether express or implied.',
'You agree to use this site only for lawful purposes, and in a manner which does not infringe the rights, or restrict, or inhibit the use and enjoyment of the site by any third party.',
'You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion, service or access of the website through which these services are provided, without explicit written permission by us',
'You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).',
'A breach or violation of any of the Terms will may result in suitable legal action, in accordance with the violation.',
'ORN Remedies reserves the rights to change these terms and conditions at any time by posting changes online. Your continued use of this site after changes are posted constitutes your acceptance of the modifications.',
] ;

const TermsCondition = () => <PolicyPage arr={[[ 'Home', '/'], ['T & C', '/terms-condition']]} name="Terms & Conditions" title="ORN Remedies Website Terms & Conditions" lidata={tc} />

const data_collect = [
'We collect the Name, Email-id, Contact number & Address of any user who registers with us.',
'All the orders and cart are recorded.',
'htmlORN Remedies doesn&apos;t collect any data that is not entered by the user himself/herself',
'Normal browsing of ORN Remedies website is anonymous and it does not collect the user personal information.'
] ;

const data_usage = [
'By signing up for various services offered by ORN Remedies, the user explicitly authorizes us to collect the information, which he/she has entered. This information is used to enhance the user experience.',
'ORN Remedies keeps the user information strictly confidential and this information is stored on a secure web server(based in India).',
'User can see his/her stored information on their profile page, if they wish to delete their information, it can be done by the using "Delete Profile" button. On Clicking the button, all stored data for the particular user will be removed from our server forever.',
'All information collected through ORN Remedies website is handled and used only by internal officials and technical support team. It is never shared with any third-party individuals or organisations.',
'html<b>DISCLOSURE:</b>We may disclose your personal information if we are required by law to do so or if you violate our Terms & Conditions',
] ;

const cookies = [
'htmlCookies are pieces of electronic data which are sent by/to ORN Remedies servers when a user registers on the website. These are placed in the hard disk of the user&apos;s computer and enables ORN Remedies to recognise the user when he/she visits the website again.',
'User can configure his/her browser so that it responds to cookies the way he/she deems fit. For example, you may want to accept or reject all cookies. The users may check their browser settings to modify their individual cookie behaviour',
'If a user disables the use of cookies on the web browser, or removes/rejects specific cookies from ORN Remedies website, then he/she may not be able to use the website as intended.'
] ;

const external_services = [
'ORN Remedies uses a few external web services on its site to display content within its web pages. For example, to display video it uses YouTube, Google Map API for displaying maps.',
'These third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.',
'However, certain third-party service providers, such as payment gateways have their own privacy policies in respect to the information we are required to provide them for your purchase-related transactions',
'For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.'
] ;

const changes = [
'As and when the need arises, ORN Remedies may alter its privacy policy in accordance with the latest technology and trends. It will provide you with timely notice of these changes. The users may reach out to ORN Remedies if they have any queries about any changes made to its practices',
'htmlIf you have any questions at all about ORN Remedies&apos;s privacy policy, please write to us at: <b>info@ornremedies.com</b>'
] ;

const PrivacyPolicy = () => {
	return (
		<div>
			<Title name="Privacy Policy"  arr={[[ 'Home', '/'], ['Privacy Policy', '/privacy-policy']]} />
			<DisplayDetailed title="What Data we collect" lidata={data_collect} />
			<DisplayDetailed title="How we Use your Data" lidata={data_usage} />
			<DisplayDetailed title="Cookies Policy" lidata={cookies} />
			<DisplayDetailed title="External Web Services" lidata={external_services} />
			<DisplayDetailed title="Changes to Privacy Policy" lidata={changes} />
		</div>
	);
}

export { RefundPolicy, ShippingPolicy, TermsCondition, PrivacyPolicy} ;