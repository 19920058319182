// import {useState} from 'react' ;
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
// import { Typeahead } from 'react-bootstrap-typeahead';
// import 'react-bootstrap-typeahead/css/Typeahead.css' ;
// import 'react-bootstrap-typeahead/css/Typeahead.bs5.css' ;

const SearchInput = ({data, onInputChange, tags, arr, multiple=false}) => {
	// const [selections, setSelections] = useState([]);
	// const {name, label, id, descr} = data ;

	// const onSelectOption = value => {
	// 	setSelections( value ) ;
	// 	onInputChange( name, value ) ;
	// }

	// const onCrossClick = str => {
	// 	let newArr = tagArray.filter(tag => tag !== str ) ;
	// 	setTagArray(newArr) ;
	// 	onInputChange(name, newArr) ;	
	// }

	return <div> This is searchInput</div> ;
	// return (
	// 	<Col md>
	// 		<Form.Group>
	// 	        <Form.Label>{label}</Form.Label>
	// 	        <Typeahead id={id} labelKey={name} multiple={multiple} onChange={onSelectOption} options={arr.map(product => product.name)} placeholder={label} selected={selections} /*minLength={1}*/ flip={true} />
	// 		    {(descr)?<Form.Text> {descr} </Form.Text>:null}
	//       	</Form.Group>
	// 	</Col>
	// ) ;
}

export default SearchInput ;