import {useState, useContext} from 'react' ;
import { Link } from'react-router-dom' ;
import CheeseburgerMenu from 'cheeseburger-menu' ;
import HamburgerMenu from 'react-hamburger-menu' ;

import Menu from './Menu.js' ;
import { addNotif, remNotif } from '../notif.js' ;
import './header.css' ;
import {UserContext} from '../../context/UserContext.js' ;
	
const headerList = [
	['Home', '/'], ['About Us', '/about'], ['Products', '/products'],
	['Distributors', '/distributors'], ['Contact', '/contact'] 
] ;

const Header = ( ) => {
	const [menuOpen, setMenuOpen] = useState(false) ; 
	const {user, loadUser, userToken} = useContext(UserContext) ;
	
	const openMenu = () => setMenuOpen(true)

	const closeMenu = () => setMenuOpen(false) 

	const onLogoutClick = () => {
		fetch('https://api.myarthhardware.com/logout' ,{
				method : 'post' ,
				headers : { 'Content-Type' : 'application/json', 
							'Authorization' : `Bearer ${userToken}`} ,
			})
			.then(res => {
				if(res.ok)
					return res.json() ;
				else
					throw Error(res.statusText) ;
			})
			.then(data =>{
				remNotif() ;	
				addNotif('Successfully Logged Out', 'success') ;	
				localStorage.clear() ;
				loadUser({}) ;
			}) 
			.catch( err  => {
				remNotif() ;
				addNotif('There was a problem with logout', 'error') ;	
				console.log(err) ; 
			}) ;
	}

	const checkLaptop = () => {
		if(window.screen.availWidth > 923)
			return (
				<div className="header-menu">
					{	headerList.map((head,i) => <Link className="header-item" key={i} to={head[1]}>{head[0]} </Link>) 
					}
				</div>
			) ;
		else
			return (
				<div>
					<CheeseburgerMenu isOpen={menuOpen} closeCallback={closeMenu}>
							<Menu closeCallback={closeMenu} onLogoutClick={onLogoutClick}/>
					</CheeseburgerMenu>
					<HamburgerMenu isOpen={menuOpen} menuClicked={openMenu} width={24} height={18} strokeWidth={4} color='white' borderRadius={1} animationDuration={0.5} />
				</div>
			) ;
	}

	const checkLoggedIn = () => {
		if(user.name)
		{
			return (
				<div className = "right-header">
					<Link className="header-btn" to="/profile"> {user.name} </Link>
					<button className="header-btn" onClick={onLogoutClick}> Logout </button>
				</div>
			) ;
		}
		else
		{
			return (
				<div className = "right-header">
					<Link className="header-btn" to='/login'> Login </Link>
					<Link className="header-btn" to='/register'> Register </Link>
				</div>
			) ;	
		}
	}
	
	return <div className="header"> {checkLaptop()} {checkLoggedIn()} </div> ;
}

export default Header ;