import { Link, BrowserRouter, Route, Routes } from 'react-router-dom' ;
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ; 
import { faMessage } from '@fortawesome/free-solid-svg-icons';

import Home from './comps/home/Home.js' ;
import NotFound from './comps/home/NotFound.js' ;
import TopBar from './comps/header/TopBar.js' ;
import ScrollTopBar from './comps/scrolltopbar/ScrollTopBar.js' ;
import About from './comps/about/About.js' ;
import Distributors from './comps/distributors/Distributors.js' ;
import DownBar from './comps/downbar/DownBar.js' ;
// import Media from './comps/media/Media.js' ;
import Contact from './comps/contact/Contact.js' ;
import Login from './comps/login/Login.js' ;
import Register from './comps/register/Register.js' ;
import Admin from './comps/admin/Admin.js' ;
import CartPage from './comps/cartpage/CartPage.js' ;
import Checkout from './comps/checkout/Checkout.js' ;
import UserProfile from './comps/userprofile/UserProfile.js' ;
import {RefundPolicy, ShippingPolicy, PrivacyPolicy, TermsCondition} from './comps/downbar/Policy.js' ;

import { ProductDataContextProvider } from './context/ProductData.js' ;
import { CartContextProvider } from './context/CartContext.js' ;
import { UserContextProvider } from './context/UserContext.js' ;

import './app.css' ;

const ContactBox = ( ) => {
	return <Link className="cb-con" to="/contact"> <FontAwesomeIcon icon={faMessage} /> </Link> ;
}
 
const App = () => {
	// const [adminMode, setAdminMode] = useState('Home') ;

	// const changeAdminMode = (str) => {
	// 	if(str.props)
	// 		str = str.props.icon.iconName ;
	// 	setAdminMode(str) ; 
 //  	}

  	const selectAdmin = () => {
  		// if(user.name === 'admin')
   			// return <AdminApp mode={adminMode} user={user} token={userToken} /> ;
   		// else
   			return(
   				<Routes>
			        <Route path='/' element={<Home />}/>
			        <Route path='/about' element={<About />} />
			        <Route path='/products' element={<Home slider="no" />}/>
			        <Route path='/admin' element={<Admin />}/>
			        <Route path='/distributors' element={<Distributors />}/>
			        <Route path='/contact' element={<Contact styles="contact-form"/>}/>
			        <Route path='/cart' element={<CartPage />}/>
			        <Route path='/checkout' element={<Checkout />}/>
			        <Route path='/login' element={<Login />}/>
			        <Route path='/register' element={<Register />}/>
			        <Route path='/profile' element={<UserProfile />}/>
			        <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
	              	<Route path='/terms-condition' element={<TermsCondition />}/>
	              	<Route path='/refund-policy' element={<RefundPolicy />} />
	              	<Route path='/shipping-policy' element={<ShippingPolicy />} />
			        <Route path="*" element={<NotFound />} />
		        </Routes>
   		) ;
  	}

	return (
		<UserContextProvider>
	 	<ProductDataContextProvider>
	 	<CartContextProvider> 
  		<div className="App">
  			<ToastContainer position="bottom-left" autoClose={5000} hideProgressBar={false}
					newestOnTop={false} closeOnClick rtl={false} pauseOnHover />
    		<BrowserRouter>
	          <div className="inner-app">
	          	<TopBar />
	          	<ScrollTopBar height={100} >
	          		<TopBar />
	          	</ScrollTopBar>
	            {selectAdmin()}
	            <ContactBox />
				<DownBar />
	          </div>
	        </BrowserRouter>
  		</div>
  		</CartContextProvider>
	    </ProductDataContextProvider>
	    </UserContextProvider>
	);
}

export default App;
