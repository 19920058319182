import Button from 'react-bootstrap/Button';

const Btn = ({data, userData, onClick, captcha, isCaptcha=false}) => {
	const {name, style} = data ;

	if((isCaptcha && captcha.length > 1) || !isCaptcha)
		return <Button className={style} onClick={() => onClick(userData)}>{name}</Button> ;
	else return null ;
}

export default Btn ;