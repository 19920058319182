import {formatDate, FormatOrder} from './format.js' ;

const orderColumns = [
	{ name: 'orderNo'}, { name: 'name'}, { name: 'mobile'}, 
	/*{ name: 'address'}, { name: 'products', format: FormatOrder},*/ 
	{ name: 'total'}, { name: 'createdAt', format: formatDate }, { name: 'status'} 
] ;

const orderDisplay = [
	{ name: 'orderNo', title:'Order No.'}, { name: 'name', title:'Name'}, { name: 'mobile', title:'Mobile No.'}, {name:'email', title:'E-Mail'}, { name: 'address', title:'Address'}, { name: 'products', title:'Products', format: FormatOrder}, { name: 'total', title:'Amount'}, { name: 'createdAt', title:'Date', format: formatDate }, { name: 'status', title:'Status'} 
] ;

const onlinePayment = {
	columns : orderColumns,
	display: orderDisplay,
	actions: {
		before: [], 
		after: ['view'],
		search: ['name', 'mobile', 'total', 'status'],
	}
}

export { onlinePayment } ;