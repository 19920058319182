import {useState, useEffect, useContext, Fragment} from 'react' ;
import {Link} from 'react-router-dom' ;

import {UserContext} from '../../context/UserContext.js' ;
import {CartContext} from '../../context/CartContext.js' ;
import './card.css' ;

const Card = ({data}) => {
	const {user} = useContext(UserContext) ;
	const {cart, add, inc} = useContext(CartContext) ;
	const {price, name, images, packing, status, stock, sno} = data ;
	const [cartValue, setCartValue] = useState(0) ;

	useEffect( () => {
		if(cart[sno])
			setCartValue(cart[sno].qty) ;
		else
			setCartValue(0) ;
	}, [cart, sno, setCartValue] ) ;

	const checkStatus = () => {
		if(status === 'yes')
			if(user.name) 
				return <button onClick={() => {
					if(cartValue === 0)
						add({sno, qty: 1}) ;
					else
						inc({sno}) ;
				}}>  Add to Cart + </button> ;
			else 
				return <Link to="/login"> Login & Add to Cart </Link>
		else
			return <button /*onClick*/>  Call & Order </button> ;
	}

	const checkStock = () => {
		if(stock === 'No')
			return <h5 className="ofs"> out of stock </h5> ;
		else
			return (
				<Fragment>
					<div className="price-con">
						<h5> &#8377;&nbsp;{price}&nbsp;/- </h5>
						<h5> {packing} </h5>
					</div>
					{checkStatus()}
				</Fragment>
			) ;
	}

	return (
		<div className="card" >
			<h4> {name} </h4>
			<div className="product-img" style={{ backgroundImage: 'url('+images[1]+')'}}> </div>
			{ checkStock() }
		</div>
	);
}

export default Card ;