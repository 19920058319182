import {useState, useEffect, useContext} from 'react' ;
import {Link} from 'react-router-dom' ;

import {UserContext} from '../../context/UserContext.js' ;
import {CartContext} from '../../context/CartContext.js' ;
import './card.css' ;

const Card2 = ({name, image, price, packing, sno, stock}) => {
	const {user} = useContext(UserContext) ;
	const {cart, add, inc} = useContext(CartContext) ;
	const [cartValue, setCartValue] = useState(0) ;

	useEffect( () => {
		if(cart[sno])
			setCartValue(cart[sno].qty) ;
		else
			setCartValue(0) ;
	}, [cart, sno, setCartValue] ) ;

	const returnBtn = () => {
		if(stock === 'No')
			return <h5 className="ofs"> out of stock </h5> ;
		else {
			if(user.name) 
				return <button onClick={() => {
						if(cartValue === 0)
							add({sno, qty: 1}) ;
						else
							inc({sno}) ;
					}}> Add to Cart + </button>
			else
				return <Link to="/login"> Login & Add to Cart </Link>
		}
	}

	return (
		<div className="card card2" >
			<h4> {name} </h4>
			<div className="product-img" style={{ backgroundImage: `url(${image})`}}> </div>
			<div className="price-con">
				<h5> &#8377;&nbsp;{price}&nbsp;/- </h5>
				<h5> {packing} </h5>
			</div>
			{returnBtn()}
		</div>
	);
}

export default Card2 ;
