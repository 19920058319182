import {useContext, useEffect, useState} from 'react' ;

import {UserContext} from '../../context/UserContext.js' ;
import Redirect from '../redirect/Redirect.js' ;
import AdminPanel from './AdminPanel.js' ; 

import {message} from './data/message.js' ;
import {activeCust} from './data/custMgmt.js' ;
import {onlinePayment} from './data/orderMgmt.js' ;
import {addProduct, manageProduct} from './data/productMgmt.js' ;
import './admin.css' ;

const Admin = () => {
	const [products, setProducts] = useState([]) ;
	const [orders, setOrders] = useState([]) ;
	const [users, setUsers] = useState([]) ;
	const [messages, setMessages] = useState([]) ;

	const {user, userToken} = useContext(UserContext) ;

	const panelData = [
		{ 	name: "Dashboard", type: "dashboard", data: [] },
		{	name: "Customers", type: "table", data: users, ...activeCust },
		{ 	name: "Orders", type: "table", data: orders, ...onlinePayment },
		{ 	name: "Product Management", type: "parent", children: [
				{ 	name: "Add Product", type: "form", ...addProduct },
				{ 	name: "Manage Product", type: "table", data: products, ...manageProduct },
			], 
		},
		{ 	name: "Messages", type: "table", data: messages, ...message },
	]

	useEffect( () => {
		fetch('https://api.myarthhardware.com/product')
		.then(res => {
			if(res.ok)
				return res.json() ;
			throw Error(res.statusText) ;
		})
		.then( data => setProducts(data) ) 
		.catch( err  => console.log(err) ) ;
	}, [])

	useEffect( () => {
		fetch('https://api.myarthhardware.com/users',{
			method : 'get' ,
			headers : {'Content-Type':'application/json', 'Authorization':`Bearer ${userToken}`} ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			throw Error(res.statusText) ;
		})
		.then( data => setUsers(data) ) 
		.catch( err  => console.log(err) ) ;
	}, [userToken])

	useEffect( () => {
		// fetch('http://localhost:8000/message',{
		fetch('https://api.myarthhardware.com/message',{
			method : 'get' ,
			headers : {'Content-Type':'application/json', 'Authorization':`Bearer ${userToken}`} ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			throw Error(res.statusText) ;
		})
		.then( data => setMessages(data) ) 
		.catch( err  => console.log(err) ) ;
	}, [userToken])

	useEffect( () => {

		fetch('https://api.myarthhardware.com/order',{
			method : 'get' ,
			headers : {'Content-Type':'application/json', 'Authorization':`Bearer ${userToken}`} ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			throw Error(res.statusText) ;
		})
		.then( data => setOrders(data.reverse()) ) 
		.catch( err  => console.log(err) ) ;
	}, [userToken])

 	if(user.email === 'admin@ornremedies.com')
		return(
			<div className="Admin">
				<AdminPanel panelData={panelData}/>				
			</div>
		) ;
	else
		return <Redirect to="/" /> ;
}

export default Admin ;