import {createContext, useState, useEffect} from 'react' ;

const ProductDataContext = createContext() ;

const byName = (a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
}

const ProductDataContextProvider = ({children}) => {
	const [productData, setProductData] = useState([]) ;

	useEffect( () => {
		fetch('https://api.myarthhardware.com/product')
		.then(res => {
			if(res.ok)
				return res.json() ;
			throw Error(res.statusText) ;
		})
		.then( data => {
			setProductData(data.sort(byName)) ;
		}) 
		.catch( err  => console.log(err) ) ;
	}, []) ;

	return (
		<ProductDataContext.Provider value={ { data: productData } }>
			{children}
		</ProductDataContext.Provider>
	) ;
}

export {ProductDataContext, ProductDataContextProvider} ;