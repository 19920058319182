import {useState} from 'react' ;
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';

const TagInput = ({data, onInputChange, tags}) => {
	const [tagText, setTagText] = useState('') ;
	const [tagArray, setTagArray] = useState([ ]) ;
	const {name, label, id, descr} = data ;

	const onTagChange = e => {
		if(e.target.value.slice(-1)===',') {
			let newArr = [...new Set([...tagArray, e.target.value.slice(0, -1).trim().toLowerCase()])] ;
			setTagArray(newArr) ;
			onInputChange(name, newArr) ;
			setTagText('') ;
		}
		else
			setTagText(e.target.value) ;
	}

	const onCrossClick = str => {
		let newArr = tagArray.filter(tag => tag !== str ) ;
		setTagArray(newArr) ;
		onInputChange(name, newArr) ;	
	}

	return (
		<Col md>
		    <FloatingLabel controlId={id} label={label}>
		    	<Form.Control type="text" value={tagText} name={name} onChange={onTagChange} placeholder={label}/>
		    	<div className="tag-flex">
		    	   	{tagArray.map(tag => <div key={tag} className="tag-con"> {tag} <span className="tag-cross" onClick={()=>onCrossClick(tag)} > X </span> </div> )}
		    	</div>
		    </FloatingLabel>
		    {(descr)?<Form.Text> {descr} </Form.Text>:null}
		</Col>
	) ;
}

export default TagInput ;