import {formatLinks} from './format.js' ;

const initProductData = {
	name: '',
	sno: 0,
	price: 0,
	packing: '',
	status: '',
	descr: '',
	discount: 0,
	images: [],
	stock: '',
	features: '',
	details: '',
	tag: '',
	category: ''
} ;

const addProductFormData = [
	[	{type: "text", name: "name", label: "Enter Product Name", id:"productName"},
		{type: "number", name: "sno", label: "Enter Sno.", id: "productSno"},
		{type: "number", name: "price", label: "Enter Price", id: "productPrice"},
	],
	[	
		{type: "text", name: "packing", label: "Packing of Product", id:"productPacking"},
		{type: "number", name: "discount", label: "Enter Discount for Product", id: "productDiscount"},
		{type: "dropdown" , name: "stock", label:"Choose Stock", options:["Yes", "No"]},
		{type: "dropdown" , name: "status", label:"Choose Status", options:["yes", "no"]},
	],
	[	{type: "textArea", name: "descr", label: "Enter Product Description", id:"productDescr"}
	],
	[	{type: "textArea", name: "features", label: "Enter Features of Product", id:"productFeatures"},
		{type: "textArea", name: "details", label: "Enter Details of Product", id:"productDetails"}
	],
	// [	{type: "text", name: "tag", label: "Enter Tag(s)", id:"productTag"},
	// 	{type: "text", name: "category", label: "Enter Category", id:"productCategory"}
	// ],
	[	{type: "multiImage", name:"images", label: "Upload Products Image(s)", id: "productImages"}
	],
	[	{type: "btn", name: "Add Product", style: "login-btn"},
	],	
] ;

const onProductAddClick = (data, userToken) => {
	const {images, name} = data ;

	const fileData = new FormData() ;
	Array.from(images).forEach(one => fileData.append('upload', one)) ;

	// console.log(Array.from(images)) ;

	fetch('https://myarth-api.herokuapp.com/sharpOrn?format=jpg',{
		method : 'post' ,
		body : fileData ,
	})
	.then(res => {
		if(res.ok)
			return res.json() ;
		throw Error(res.statusText) ;
	})
	.then(resp => {
		return fetch(`https://api.myarthhardware.com/uploadMany?name=${name}&folder=product&subfolder=${name}&format=jpg`,{
			method : 'post' ,
			headers : { 'Authorization' : `Bearer ${userToken}`, 'Content-Type' : 'application/json' } ,
			body : JSON.stringify(resp) ,
		})
	})
	.then(res2 => {
		if(res2.ok)
			return res2.json() ;
		throw Error(res2.statusText) ;
	})
	.then(resp2 =>{	
		console.log(resp2) ;
		// Product Add karwana hai
	})
	.catch( err  => {
		// addNotif('Failed to Upload Image', 'error') ;
		console.log(err) 
	}) ;
}

const addProductFormErrorObj = {
	name: "blank",
	sno: "number",
	price: "number",
	packing: "blank",
	status: "blank",
	descr: "blank",
	// images: [],
	stock: "blank"
} ;

const productColumns = [ 
	{ name: 'sno'}, { name: 'name'}, { name: 'price'}, { name: 'packing'},
	{ name: 'images', format: formatLinks}
] ;

const productDisplay = [ 
	{ name: 'sno', title:'Sno.'}, { name: 'name', title:'Name'}, { name: 'price', title:'Price'}, { name: 'packing', title:'Packing'}, {name:'category', title:'Categpry'}, {name:'descr', title:'Description'}, {name:'details', title:'Details'}, {name:'discount', title:'Discount'}, {name:'features', title:'Features'}, {name:'status', title:'Status'}, {name:'stock', title:'Stock'}, {name:'tag', title:'Tags'}, { name: 'images', title:'Images', format: formatLinks}
] ;

const addProduct = {
	formData: addProductFormData, 
	initData: initProductData, 
	sendRequest: onProductAddClick, 
	errorObj: addProductFormErrorObj, 
	btnText: "Add Product"
} ;

const onEditClick = (data) => {
	console.log(data) ;
	// fetch lagana hai
}

const onDeleteClick = () => {
	console.log('deleted') ;
}

const editProductForm = [
	[	{type: "text", name: "name", label: "Enter Product Name", id:"productName"},
		{type: "number", name: "price", label: "Enter Price", id: "productPrice"},
	],
	[	
		{type: "text", name: "packing", label: "Packing of Product", id:"productPacking"},
		{type: "number", name: "discount", label: "Enter Discount for Product", id: "productDiscount"},
	],
	[	{type: "dropdown" , name: "stock", label:"Choose Stock", options:["Yes", "No"]},
		{type: "dropdown" , name: "status", label:"Choose Status", options:["yes", "no"]},
	],
	[	{type: "textArea", name: "descr", label: "Enter Product Description", id:"productDescr"}
	],
	[	{type: "textArea", name: "features", label: "Enter Features of Product", id:"productFeatures"},
		{type: "textArea", name: "details", label: "Enter Details of Product", id:"productDetails"}
	],
	// [	{type: "text", name: "tag", label: "Enter Tag(s)", id:"productTag"},
	// 	{type: "text", name: "category", label: "Enter Category", id:"productCategory"}
	// ],
	// [	{type: "multiImage", name:"images", label: "Upload Products Image(s)", id: "productImages"}
	// ],
	[	{type: "btn", name: "Submit", style: "login-btn"},
	],	
] ;

const manageProduct = {
	columns: productColumns, 
	display: productDisplay,
	actions: {
		before: ['checkbox'], 
		after: ['switch', 'view', 'edit', 'delete'],
		search: ['name', 'packing', 'price'],
		delete: onDeleteClick
	},
	editData : {
		name: "Edit Product",
		formData: editProductForm,
		sendRequest: onEditClick, 
		errorObj: addProductFormErrorObj, 
		btnText: "Submit"
	}
} ;

export { addProduct, manageProduct } ;