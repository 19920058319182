import {useContext} from 'react' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' ;
import { faPhone, faEnvelope, faCartShopping } from '@fortawesome/free-solid-svg-icons' ;
import { Link } from 'react-router-dom' ;
import AnimateOnChange from 'react-animate-on-change' ;

import {UserContext} from '../../context/UserContext.js' ;
import {CartContext} from '../../context/CartContext.js' ;
import Header from './Header.js'
import './topbar.css' ;

const CartNumber = ({num}) => {
	return (
		<AnimateOnChange baseClassName="cart-number" animationClassName="shake-vertical" animate={num !== 0}>  {num} 
    	</AnimateOnChange>
	)  ;
}
 
const TopBar = () => {	
	const {user} = useContext(UserContext) ;
	const {cart} = useContext(CartContext) ;

	const url = (user.name)?'/cart':'/login' ;

	const checkMobile = () => {
		if(window.screen.availWidth > 600)
			return (
				<div className="right-topbar">
					<div className='bar-item'> 
				 		<div>
				 			<FontAwesomeIcon icon={faPhone} />
				 		 	<a className="bar-item-a" href="tel:09312245125">
				 		 		+91 93122-45125
				 		 	</a>
				 		</div>
				 		<div>
				 			<FontAwesomeIcon icon={faEnvelope} />
				 		 	<a className="bar-item-a" href="mailto:info@ornremedies.com">info@ornremedies.com
				 		 	</a>
				 		</div>
					</div>
		 			<div className="bar-item big">
		 				<Link to={url}> 
		 					<FontAwesomeIcon icon={faCartShopping} />
		 					<CartNumber num={cart.filter(one => one).length} /> 
		 				</Link>
		 			</div>
				</div>
			) ;
		else
			return (
				<div className="right-topbar">
			 		<div className="bar-item">
			 			<a className="bar-item-a" href="tel:09312245125">
			 		 		<FontAwesomeIcon icon={faPhone} />
			 		 	</a>
			 		 	<a className="bar-item-a" href="mailto:info@ornremedies.com">
			 		 		<FontAwesomeIcon icon={faEnvelope} />
			 		 	</a>
		 				<Link className="bar-item-a" to={url}>
		 					<CartNumber num={cart.filter(one => one).length} />
		 					<FontAwesomeIcon icon={faCartShopping} /> 
		 				</Link>
		 			</div>
				</div>
			) ;
	}

	let logoLink = (window.screen.availWidth > 600)?'https://api.myarth.in/static/images/orn/Logo2.jpg':'https://api.myarth.in/static/images/orn/Logo.jpg' ;

	return (
		<div className="topbar"> 
			<div className="topbar-top">
				<div className="logo"> <img src={logoLink} alt="logo" /></div>
				{checkMobile()}
			</div>
	        <Header />
		</div>
	) ;
}

export default TopBar ;