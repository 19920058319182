import {useState, useContext} from 'react' ;

import Redirect from '../redirect/Redirect.js' ;
import { addNotif, remNotif } from '../notif.js' ;
import { invalidPass, isBlank } from '../valid.js' ;
import Register from '../register/Register.js' ;
import LoginForm from '../forms/LoginForm.js' ;
import TextBoxIcon from '../textboxicon/TextBoxIcon.js' ;
import {UserContext} from '../../context/UserContext.js' ;
import './UserProfile.css' ;
import './Data.css' ;

const Data = ({item, value}) => {	
	return(
		<div className ="data-flex">
			<p className ="data-bold" >{item}</p>
			<p className ="data-right" >{value}</p>
		</div>
	) ;
}

const keyObject = {
	name :"Name",
	address :"Address",
	email :"E-Mail",
	mobile :"Mobile ",
} ;

const initData = { oldpass: '', newpass: '', repass: ''} ;

const UserProfile = () => {	
	const [mode, setMode] = useState('normal') ;
	const [data, setData] = useState(initData) ;
	const [error, setError] = useState('') ;
	const {user, loadUser, userToken} = useContext(UserContext) ;

	const formatDate = (dt) => {
		const dat = new Date(dt).toLocaleString("en-GB", {timeZone: "Asia/Kolkata"}); ;
		return dat ;
	}

	const onLogoutClick = () => {
		fetch('https://api.myarthhardware.com/logoutAll' ,{
			method : 'post' ,
			headers : { 'Content-Type' : 'application/json', 
						'Authorization' : `Bearer ${userToken}`} ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then(data =>{	
			remNotif() ;
			addNotif('Successfully Logged Out', 'success') ;	
			loadUser({}) ;
		}) 
		.catch( err  => {
			remNotif() ;
			addNotif('There was a problem with logout', 'error') ;	
			console.log(err) ; 
		}) ;
	}

	const onEditClick = () => setMode( (mode==='edit'?'normal':'edit') ) ; 
	const onChangepassClick = () => setMode ( (mode==='change'?'normal':'change') ) ;

	const onDeleteClick = () => {
		fetch('https://api.myarthhardware.com/users/me',{
				method : 'delete' ,
				headers : { 'Content-Type' : 'application/json', 
							'Authorization' : `Bearer ${userToken}`} ,
		})
		.then(res => {
			if(res.ok)
				return res.json() ;
			else
				throw Error(res.statusText) ;
		})
		.then(data =>{	
			remNotif() ;
			addNotif('Successfully Deleted', 'success') ;
			loadUser({}) ;
		}) 
		.catch( err  => {
			remNotif() ;
			addNotif('Error Deleting Profile', 'error') ;	
			console.log(err) ; 
		}) ;
	}
	
	const onChangeClick = () => {
		const {oldpass, repass, newpass} = data ;

		if(error !== '')
			setError('You must fix all errors before proceeding') ;
		else
		{
			if( isBlank(oldpass, 'Old Password') )
				setError(isBlank(oldpass, 'Old Password') ) ;
			else if ( invalidPass(newpass, repass) )
				setError(invalidPass(newpass, repass) ) ;
			else
			{	fetch('https://api.myarthhardware.com/users/me/change',{
						method : 'post' ,
						headers : { 'Content-Type' : 'application/json', 
									'Authorization' : `Bearer ${userToken}`} ,
						body: JSON.stringify({ oldpass, newpass})
				})
				.then(res => {
					if(res.ok)
						return res.json() ;
					else
						throw Error(res.statusText) ;
				})
				.then(data =>{	
					setData(initData) ;
					setError('') ;
					remNotif() ;
					addNotif('Successfully changed the password', 'success') ;
					loadUser({}) ;
				})
				.catch( err  => {
					remNotif() ;
					addNotif('Incorrect Old Password', 'error') ;
					setError('Incorrect Old Password') ;	
					console.log(err) ; 
				}) ;
			}
		}
	}

	const generateData = () => {
		return Object.keys(user).map( (one,i) => {
			if(keyObject[one])
				return <Data key={i} item={keyObject[one]} value={user[one]} />
			else 
				return null ;
		}) ;
	}

	const checkMode = () => {
		if(mode === 'edit')
			return <Register mode="edit" edit={onEditClick}/>
		else if(mode === 'change')
			return (
				<LoginForm title=" Change Password " error={error} b2="Change" onb2Click={onChangeClick} css="up-form">
					<TextBoxIcon name="oldpass" value={data.oldpass} placeHolder="Enter Old Password" password onChange={onInputChange}/>
					<TextBoxIcon name="newpass" value={data.newpass} placeHolder="Enter New Password" password onChange={onInputChange}/>
					<TextBoxIcon name="repass" value={data.repass} placeHolder="Re-Enter New Password" password onChange={onInputChange}/>
				</LoginForm>
			) ;
		else
			return (
				<div>
					{generateData()}
					<Data item="Created at" value={formatDate(user.createdAt)} />
				</div>
			) ;
	}

	const onInputChange = (event) => {
		setData({...data, [event.target.name] : event.target.value }) ;
		setError('') ;
	}

	if(user.name)
	{
		return (
			<div className="user-profile">
				<div className="up-left">
					<button className = "up-button" onClick={onChangepassClick}>
						{(mode==='change'?'Go Back':'Change password')}
					</button>
					<button className = "up-button" onClick={onLogoutClick}>Logout</button>
					<button className = "up-button " onClick = {onEditClick}>
						{(mode==='edit'?'Go Back':'Edit profile')}
					</button>
					<button className = "up-button up-del" onClick={onDeleteClick}>Delete profile</button>
				</div>
				<div className="up-right"> {checkMode()} </div>
			</div>
		) ;
	}
	else
		return <Redirect to = '/login' />
}

export default UserProfile ;					